<template>
  <router-view class="bg-transparent-51"></router-view>
</template>

<script>
export default {
  name: "my-vue",
  components: {},
};
</script>

<style>
.bg-transparent-51 {
  margin-top: 90px;
  color: white;
  background-color: rgba(61, 77, 141, 0.936);
}
</style>
