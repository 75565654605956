<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3" :class="{ 'd-none d-md-block': !isSidebarOpen }">
     
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link" href="#">
                  <span v-if="isSidebarOpen">图标 + 文字</span>
                  <span v-else>图标</span>
                </a>
              </li>
           
            </ul>
          </div>
        </nav>
      </div>
      <div class="col-md-9" :class="{ 'col-md-12': !isSidebarOpen }">
      
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          @click="toggleSidebar"
        >
          123
        </button>
        <h1>主内容</h1>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "home-vue",
  data() {
    return {
      isSidebarOpen: true, // 控制侧边栏是否打开
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
};
</script>
<style scoped></style>
