export let countryList = [
  // {
  //   countryCode: "AF",
  //   countryName: "阿富汗",
  //   countryEName: "Afghanistan",
  //   img: "Afghanistan.png",
  // },
  // {
  //   countryCode: "AL",
  //   countryName: "阿尔巴尼亚",
  //   countryEName: "Albania",
  //   img: "Albania.png",
  // },
  // {
  //   countryCode: "DZ",
  //   countryName: "阿尔及利亚",
  //   countryEName: "Algeria",
  //   img: "Algeria.png",
  // },
  // {
  //   countryCode: "AD",
  //   countryName: "安道尔",
  //   countryEName: "Andorra",
  //   img: "Andorra.png",
  // },
  // {
  //   countryCode: "AO",
  //   countryName: "安哥拉",
  //   countryEName: "Angola",
  //   img: "Angola.png",
  // },
  // {
  //   countryCode: "AV",
  //   countryName: "安圭拉岛",
  //   countryEName: "Anguilla Island",
  //   img: "AnguillaIsland.png",
  // },
  // {
  //   countryCode: "AG",
  //   countryName: "安提瓜和巴布达",
  //   countryEName: "Antigua and Barbuda",
  //   img: "Antigua and Barbuda.png",
  // },
  // {
  //   countryCode: "AR",
  //   countryName: "阿根廷",
  //   countryEName: "Argentina",
  //   img: "Argentina.png",
  // },
  // {
  //   countryCode: "AM",
  //   countryName: "亚美尼亚",
  //   countryEName: "Armenia",
  //   img: "Armenia.jpg",
  // },
  // {
  //   countryCode: "AA",
  //   countryName: "阿鲁巴",
  //   countryEName: "Aruba",
  //   img: "Aruba.png",
  // },
  // {
  //   countryCode: "AU",
  //   countryName: "澳大利亚",
  //   countryEName: "Australia",
  //   img: "Australia.png",
  // },
  // {
  //   countryCode: "AT",
  //   countryName: "奥地利",
  //   countryEName: "Austria",
  //   img: "Austria.png",
  // },
  // {
  //   countryCode: "AZ",
  //   countryName: "阿塞拜疆",
  //   countryEName: "Azerbaijan",
  //   img: "Azerbaijan.png",
  // },
  // {
  //   countryCode: "BS",
  //   countryName: "巴哈马",
  //   countryEName: "Bahamas",
  //   img: "Bahamas.png",
  // },
  // {
  //   countryCode: "BH",
  //   countryName: "巴林",
  //   countryEName: "Bahrain",
  //   img: "Bahrain.png",
  // },
  // {
  //   countryCode: "BD",
  //   countryName: "孟加拉",
  //   countryEName: "Bangladesh",
  //   img: "Bangladesh.png",
  // },
  // {
  //   countryCode: "BB",
  //   countryName: "巴巴多斯",
  //   countryEName: "Barbados",
  //   img: "Barbados.jpg",
  // },
  // {
  //   countryCode: "BY",
  //   countryName: "白俄罗斯",
  //   countryEName: "Belarus",
  //   img: "Belarus.png",
  // },
  // {
  //   countryCode: "BE",
  //   countryName: "比利时",
  //   countryEName: "Belgium",
  //   img: "Belgium.png",
  // },
  // {
  //   countryCode: "BZ",
  //   countryName: "伯里兹",
  //   countryEName: "Belize",
  //   img: "Belize.png",
  // },
  // {
  //   countryCode: "BJ",
  //   countryName: "贝宁",
  //   countryEName: "Benin",
  //   img: "Benin.png",
  // },
  // {
  //   countryCode: "BM",
  //   countryName: "百慕大",
  //   countryEName: "Bermuda",
  //   img: "Bermuda.jpg",
  // },
  // {
  //   countryCode: "BT",
  //   countryName: "不丹",
  //   countryEName: "Bhutan",
  //   img: "Bhutan.png",
  // },
  // {
  //   countryCode: "BO",
  //   countryName: "玻利维亚",
  //   countryEName: "Bolivia",
  //   img: "Bolivia.png",
  // },
  // {
  //   countryCode: "BA",
  //   countryName: "波黑",
  //   countryEName: "Bosnia and Herzegovina",
  //   img: "BosniaAndHerzegovina.png",
  // },
  // {
  //   countryCode: "BW",
  //   countryName: "博茨瓦纳",
  //   countryEName: "Botswana",
  //   img: "Botswana.png",
  // },
  // {
  //   countryCode: "BV",
  //   countryName: "布韦岛",
  //   countryEName: "Bouvet Island",
  //   img: "BouvetIsland.png",
  // },
  {
    countryCode: "BR",
    countryName: "巴西",
    countryEName: "Brazil",
    img: "Brazil.png",
  },
  // {
  //   countryCode: "IO",
  //   countryName: "英属印度洋领地",
  //   countryEName: "British Indian Ocean Territory",
  //   img: "British Indian Ocean Territory.jpg",
  // },
  // {
  //   countryCode: "BN",
  //   countryName: "文莱布鲁萨兰",
  //   countryEName: "Brunei",
  //   img: "Brunei.png",
  // },
  // {
  //   countryCode: "BG",
  //   countryName: "保加利亚",
  //   countryEName: "Bulgaria",
  //   img: "Bulgaria.png",
  // },
  // {
  //   countryCode: "BF",
  //   countryName: "布基纳法索",
  //   countryEName: "Burkina Faso",
  //   img: "Burkina-faso.png",
  // },
  // {
  //   countryCode: "BI",
  //   countryName: "布隆迪",
  //   countryEName: "Burundi",
  //   img: "Burundi.png",
  // },
  // {
  //   countryCode: "KH",
  //   countryName: "柬埔寨",
  //   countryEName: "Cambodia",
  //   img: "Cambodia.png",
  // },
  // {
  //   countryCode: "CM",
  //   countryName: "喀麦隆",
  //   countryEName: "Cameroon",
  //   img: "Cameroon.png",
  // },
  // {
  //   countryCode: "CA",
  //   countryName: "加拿大",
  //   countryEName: "Canada",
  //   img: "Canada.png",
  // },
  // {
  //   countryCode: "CV",
  //   countryName: "佛得角",
  //   countryEName: "Cape Verde",
  //   img: "Cape Verde.png",
  // },
  // {
  //   countryCode: "KY",
  //   countryName: "开曼群岛",
  //   countryEName: "Cayman",
  //   img: "Cayman.png",
  // },
  // {
  //   countryCode: "CF",
  //   countryName: "中非",
  //   countryEName: "Central African Republic",
  //   img: "Central African Republic.png",
  // },
  // {
  //   countryCode: "TD",
  //   countryName: "乍得",
  //   countryEName: "Chad",
  //   img: "Chad.png",
  // },
  // {
  //   countryCode: "CL",
  //   countryName: "智利",
  //   countryEName: "Chile",
  //   img: "Chile.png",
  // },
  {
    countryCode: "CN",
    countryName: "中国",
    countryEName: "China",
    img: "China.png",
  },
  // {
  //   countryCode: "CX",
  //   countryName: "圣诞岛",
  //   countryEName: "Christmas Island",
  //   img: "ChristmaxIsland.jpg",
  // },
  // {
  //   countryCode: "CC",
  //   countryName: "科科斯群岛",
  //   countryEName: "Cocos Islands",
  //   img: "Cocos Islands.png",
  // },
  // {
  //   countryCode: "CO",
  //   countryName: "哥伦比亚",
  //   countryEName: "Colombia",
  //   img: "Colombia.png",
  // },
  // {
  //   countryCode: "DO",
  //   countryName: "多米尼加联邦",
  //   countryEName: "Commonwealth of Dominica",
  //   img: "Commonwealth of Dominica.png",
  // },
  // {
  //   countryCode: "KM",
  //   countryName: "科摩罗",
  //   countryEName: "Comoros",
  //   img: "Comoros.png",
  // },
  // {
  //   countryCode: "CG",
  //   countryName: "刚果（布）",
  //   countryEName: "Congo",
  //   img: "Congo.png",
  // },
  // {
  //   countryCode: "CD",
  //   countryName: "刚果（金）",
  //   countryEName: "Congo-Kinshasa",
  //   img: "Congo-kinshasa.png",
  // },
  // {
  //   countryCode: "CK",
  //   countryName: "库克群岛",
  //   countryEName: "Cook Islands",
  //   img: "Cook Islands.jpg",
  // },
  // {
  //   countryCode: "CR",
  //   countryName: "哥斯达黎加",
  //   countryEName: "Costa Rica",
  //   img: "Costa Rica.png",
  // },
  // {
  //   countryCode: "HR",
  //   countryName: "克罗地亚",
  //   countryEName: "Croatia",
  //   img: "Croatia.png",
  // },
  // {
  //   countryCode: "CU",
  //   countryName: "古巴",
  //   countryEName: "Cuba",
  //   img: "Cuba.png",
  // },
  // {
  //   countryCode: "CY",
  //   countryName: "塞普路斯",
  //   countryEName: "Cyprus",
  //   img: "Cyprus.png",
  // },
  // {
  //   countryCode: "CZ",
  //   countryName: "捷克",
  //   countryEName: "Czech Republic",
  //   img: "CzechRepublic.png",
  // },
  // {
  //   countryCode: "DK",
  //   countryName: "丹麦",
  //   countryEName: "Denmark",
  //   img: "Denmark.png",
  // },
  // {
  //   countryCode: "DJ",
  //   countryName: "吉布提",
  //   countryEName: "Djibouti",
  //   img: "Djibouti.png",
  // },
  // {
  //   countryCode: "DM",
  //   countryName: "多米尼加共和国",
  //   countryEName: "Dominican Republic",
  //   img: "Dominican Republic.png",
  // },
  // {
  //   countryCode: "AS",
  //   countryName: "东萨摩亚",
  //   countryEName: "Eastern Samoa",
  //   img: "EasternSamoa.png",
  // },
  // {
  //   countryCode: "EC",
  //   countryName: "厄瓜多尔",
  //   countryEName: "Ecuador",
  //   img: "Ecuador.png",
  // },
  {
    countryCode: "EG",
    countryName: "埃及",
    countryEName: "Egypt",
    img: "Egypt.png",
  },
  // {
  //   countryCode: "SV",
  //   countryName: "萨尔瓦多",
  //   countryEName: "El Salvador",
  //   img: "EISalvador.png",
  // },
  // {
  //   countryCode: "GQ",
  //   countryName: "赤道几内亚",
  //   countryEName: "Equatorial Guinea",
  //   img: "EqutorialGuinea.png",
  // },
  // {
  //   countryCode: "ER",
  //   countryName: "厄立特里亚",
  //   countryEName: "Eritrea",
  //   img: "Eritrea.png",
  // },
  // {
  //   countryCode: "EE",
  //   countryName: "爱沙尼亚",
  //   countryEName: "Estonia",
  //   img: "Estonia.png",
  // },
  // {
  //   countryCode: "SZ",
  //   countryName: "斯威士兰",
  //   countryEName: "Eswatini",
  //   img: "Swaziland.png",
  // },
  // {
  //   countryCode: "ET",
  //   countryName: "埃塞俄比亚",
  //   countryEName: "Ethiopia",
  //   img: "Ethiopia.png",
  // },
  // {
  //   countryCode: "FK",
  //   countryName: "福兰克群岛",
  //   countryEName: "Falkland Islands",
  //   img: "Falkland Islands.png",
  // },
  // {
  //   countryCode: "FO",
  //   countryName: "法罗群岛",
  //   countryEName: "Faroe Islands",
  //   img: "FaroeIslands.jpg",
  // },
  // {
  //   countryCode: "FJ",
  //   countryName: "斐济",
  //   countryEName: "Fiji",
  //   img: "Fiji.jpg",
  // },
  // {
  //   countryCode: "FI",
  //   countryName: "芬兰",
  //   countryEName: "Finland",
  //   img: "Finland.png",
  // },
  // {
  //   countryCode: "FR",
  //   countryName: "法国",
  //   countryEName: "France",
  //   img: "France.png",
  // },
  // {
  //   countryCode: "GF",
  //   countryName: "法属圭亚那",
  //   countryEName: "French Guiana",
  //   img: "French Guiana.jpg",
  // },
  // {
  //   countryCode: "RE",
  //   countryName: "法属尼留旺岛",
  //   countryEName: "French Niliuwang Island",
  //   img: "French Niliuwang Island.png",
  // },
  // {
  //   countryCode: "PF",
  //   countryName: "法属波利尼西亚",
  //   countryEName: "French Polynesia",
  //   img: "french polynesia.png",
  // },
  // {
  //   countryCode: "TF",
  //   countryName: "法国南部领地",
  //   countryEName: "French Southern Territories",
  //   img: "French Southern Territories.png",
  // },
  // {
  //   countryCode: "GA",
  //   countryName: "加蓬",
  //   countryEName: "Gabon",
  //   img: "Gabon.png",
  // },
  // {
  //   countryCode: "GM",
  //   countryName: "冈比亚",
  //   countryEName: "Gambia",
  //   img: "Gambia.png",
  // },
  // {
  //   countryCode: "GE",
  //   countryName: "格鲁吉亚",
  //   countryEName: "Georgia",
  //   img: "Georgia.png",
  // },
  {
    countryCode: "DE",
    countryName: "德国",
    countryEName: "Germany",
    img: "Germany.png",
  },
  // {
  //   countryCode: "GH",
  //   countryName: "加纳",
  //   countryEName: "Ghana",
  //   img: "Ghana.png",
  // },
  // {
  //   countryCode: "GI",
  //   countryName: "直布罗陀",
  //   countryEName: "Gibraltar",
  //   img: "Gibraltar.jpg",
  // },
  // {
  //   countryCode: "GR",
  //   countryName: "希腊",
  //   countryEName: "Greece",
  //   img: "Greece.png",
  // },
  // {
  //   countryCode: "GL",
  //   countryName: "格陵兰岛",
  //   countryEName: "Greenland",
  //   img: "Greenland.png",
  // },
  // {
  //   countryCode: "GD",
  //   countryName: "格林纳达",
  //   countryEName: "Grenada",
  //   img: "Grenada.png",
  // },
  // {
  //   countryCode: "GU",
  //   countryName: "关岛",
  //   countryEName: "Guam",
  //   img: "Guam.jpg",
  // },
  // {
  //   countryCode: "GT",
  //   countryName: "危地马拉",
  //   countryEName: "Guatemala",
  //   img: "Guatemala.png",
  // },
  // {
  //   countryCode: "GN",
  //   countryName: "几内亚",
  //   countryEName: "Guinea",
  //   img: "Guinea.png",
  // },
  // {
  //   countryCode: "GW",
  //   countryName: "几内亚比绍",
  //   countryEName: "Guinea-Bissau",
  //   img: "Guinea-bissau.png",
  // },
  // {
  //   countryCode: "GY",
  //   countryName: "圭亚那",
  //   countryEName: "Guyana",
  //   img: "Guyana.png",
  // },
  // {
  //   countryCode: "HT",
  //   countryName: "海地",
  //   countryEName: "Haiti",
  //   img: "Haiti.png",
  // },
  // {
  //   countryCode: "HN",
  //   countryName: "洪都拉斯",
  //   countryEName: "Honduras",
  //   img: "Honduras.png",
  // },
  // {
  //   countryCode: "HK",
  //   countryName: "中国香港特区",
  //   countryEName: "Hong Kong",
  //   img: "Hongkong.jpg",
  // },
  // {
  //   countryCode: "HU",
  //   countryName: "匈牙利",
  //   countryEName: "Hungary",
  //   img: "Hungary.png",
  // },
  // {
  //   countryCode: "IS",
  //   countryName: "冰岛",
  //   countryEName: "Iceland",
  //   img: "Iceland.png",
  // },
  {
    countryCode: "IN",
    countryName: "印度",
    countryEName: "India",
    img: "India.png",
  },
  {
    countryCode: "ID",
    countryName: "印度尼西亚",
    countryEName: "Indonesia",
    img: "Indonesia.png",
  },
  {
    countryCode: "IR",
    countryName: "伊朗",
    countryEName: "Iran",
    img: "Iran.png",
  },
  // {
  //   countryCode: "IQ",
  //   countryName: "伊拉克",
  //   countryEName: "Iraq",
  //   img: "Iraq.png",
  // },
  // {
  //   countryCode: "IE",
  //   countryName: "爱尔兰",
  //   countryEName: "Ireland",
  //   img: "Ireland.png",
  // },
  // {
  //   countryCode: "IL",
  //   countryName: "以色列",
  //   countryEName: "Israel",
  //   img: "Israel.png",
  // },
  // {
  //   countryCode: "IT",
  //   countryName: "意大利",
  //   countryEName: "Italy",
  //   img: "Italy.png",
  // },
  // {
  //   countryCode: "CI",
  //   countryName: "象牙海岸",
  //   countryEName: "Ivory Coast",
  //   img: "Ivory Coast.png",
  // },
  // {
  //   countryCode: "JM",
  //   countryName: "牙买加",
  //   countryEName: "Jamaica",
  //   img: "Jamaica.png",
  // },
  {
    countryCode: "JP",
    countryName: "日本",
    countryEName: "Japan",
    img: "Japan.png",
  },
  // {
  //   countryCode: "JO",
  //   countryName: "约旦",
  //   countryEName: "Jordan",
  //   img: "Jordan.png",
  // },
  // {
  //   countryCode: "KZ",
  //   countryName: "哈萨克斯坦",
  //   countryEName: "Kazakhstan",
  //   img: "Kazakstan.png",
  // },
  // {
  //   countryCode: "KE",
  //   countryName: "肯尼亚",
  //   countryEName: "Kenya",
  //   img: "Kenya.png",
  // },
  // {
  //   countryCode: "KI",
  //   countryName: "基里巴斯",
  //   countryEName: "Kiribati",
  //   img: "Kiribati.jpg",
  // },
  // {
  //   countryCode: "KW",
  //   countryName: "科威特",
  //   countryEName: "Kuwait",
  //   img: "Kuwait.png",
  // },
  // {
  //   countryCode: "KG",
  //   countryName: "吉尔吉斯斯坦",
  //   countryEName: "Kyrgyzstan",
  //   img: "Kyrgyzstan.png",
  // },
  // {
  //   countryCode: "LA",
  //   countryName: "老挝",
  //   countryEName: "Laos",
  //   img: "Laos.png",
  // },
  // {
  //   countryCode: "LV",
  //   countryName: "拉托维亚",
  //   countryEName: "Latvia",
  //   img: "Latvia.png",
  // },
  // {
  //   countryCode: "LB",
  //   countryName: "黎巴嫩",
  //   countryEName: "Lebanon",
  //   img: "Lebanon.png",
  // },
  // {
  //   countryCode: "LS",
  //   countryName: "莱索托",
  //   countryEName: "Lesotho",
  //   img: "Lesotho.png",
  // },
  // {
  //   countryCode: "LR",
  //   countryName: "利比里亚",
  //   countryEName: "Liberia",
  //   img: "Liberia.png",
  // },
  // {
  //   countryCode: "LY",
  //   countryName: "利比亚",
  //   countryEName: "Libya",
  //   img: "Libya.png",
  // },
  // {
  //   countryCode: "LI",
  //   countryName: "列支顿士登",
  //   countryEName: "Liechtenstein",
  //   img: "Liechtenstein.png",
  // },
  // {
  //   countryCode: "LT",
  //   countryName: "立陶宛",
  //   countryEName: "Lithuania",
  //   img: "Lithuania.png",
  // },
  // {
  //   countryCode: "LU",
  //   countryName: "卢森堡",
  //   countryEName: "Luxembourg",
  //   img: "Luxembourg.png",
  // },
  // {
  //   countryCode: "MO",
  //   countryName: "中国澳门特区",
  //   countryEName: "Macau",
  //   img: "Macao.jpg",
  // },
  // {
  //   countryCode: "MG",
  //   countryName: "马达加斯加",
  //   countryEName: "Madagascar",
  //   img: "Madagascar.png",
  // },
  // {
  //   countryCode: "MW",
  //   countryName: "马拉维",
  //   countryEName: "Malawi",
  //   img: "Malawi.png",
  // },
  // {
  //   countryCode: "MY",
  //   countryName: "马来西亚",
  //   countryEName: "Malaysia",
  //   img: "Malaysia.png",
  // },
  // {
  //   countryCode: "MV",
  //   countryName: "马尔代夫",
  //   countryEName: "Maldives",
  //   img: "Maldives.png",
  // },
  // {
  //   countryCode: "ML",
  //   countryName: "马里",
  //   countryEName: "Mali",
  //   img: "Mali.png",
  // },
  // {
  //   countryCode: "MT",
  //   countryName: "马耳他",
  //   countryEName: "Malta",
  //   img: "Malta.png",
  // },
  // {
  //   countryCode: "MH",
  //   countryName: "马绍尔群岛",
  //   countryEName: "Marshall Islands",
  //   img: "Marshall Islands.jpg",
  // },
  // {
  //   countryCode: "MR",
  //   countryName: "毛里塔尼亚",
  //   countryEName: "Mauritania",
  //   img: "Mauritania.png",
  // },
  // {
  //   countryCode: "MU",
  //   countryName: "毛里求斯",
  //   countryEName: "Mauritius",
  //   img: "Mauritius.png",
  // },
  // {
  //   countryCode: "YT",
  //   countryName: "马约特",
  //   countryEName: "Mayotte",
  //   img: "Mayotte.jpg",
  // },
  {
    countryCode: "MX",
    countryName: "墨西哥",
    countryEName: "Mexico",
    img: "Mexico.png",
  },
  // {
  //   countryCode: "FM",
  //   countryName: "密克罗尼西亚",
  //   countryEName: "Micronesia",
  //   img: "Micronesia.png",
  // },
  // {
  //   countryCode: "MD",
  //   countryName: "摩尔多瓦",
  //   countryEName: "Moldova",
  //   img: "Moldova.png",
  // },
  // {
  //   countryCode: "MC",
  //   countryName: "摩纳哥",
  //   countryEName: "Monaco",
  //   img: "Monaco.png",
  // },
  // {
  //   countryCode: "MN",
  //   countryName: "蒙古",
  //   countryEName: "Mongolia",
  //   img: "Mongolia.png",
  // },
  // {
  //   countryCode: "MS",
  //   countryName: "蒙塞拉特岛",
  //   countryEName: "Montserrat",
  //   img: "Montserrat Island.jpg",
  // },
  // {
  //   countryCode: "MA",
  //   countryName: "摩洛哥",
  //   countryEName: "Morocco",
  //   img: "Morocco.png",
  // },
  // {
  //   countryCode: "MZ",
  //   countryName: "莫桑比克",
  //   countryEName: "Mozambique",
  //   img: "Mozambique.png",
  // },
  // {
  //   countryCode: "NA",
  //   countryName: "纳米比亚",
  //   countryEName: "Namibia",
  //   img: "Namibia.png",
  // },
  // {
  //   countryCode: "NR",
  //   countryName: "瑙鲁",
  //   countryEName: "Nauru",
  //   img: "Nauru.jpg",
  // },
  // {
  //   countryCode: "NP",
  //   countryName: "尼泊尔",
  //   countryEName: "Nepal",
  //   img: "Nepal.png",
  // },
  // {
  //   countryCode: "NL",
  //   countryName: "荷兰",
  //   countryEName: "Netherlands",
  //   img: "Netherlands.png",
  // },
  // {
  //   countryCode: "AN",
  //   countryName: "荷属安的列斯群岛",
  //   countryEName: "Netherlands Antilles",
  //   img: "netherlands antilles.png",
  // },
  // {
  //   countryCode: "NC",
  //   countryName: "新卡里多尼亚",
  //   countryEName: "New Caledonia",
  //   img: "New Caledonia.jpg",
  // },
  // {
  //   countryCode: "NZ",
  //   countryName: "新西兰",
  //   countryEName: "New Zealand",
  //   img: "New Zealand.jpg",
  // },
  // {
  //   countryCode: "NI",
  //   countryName: "尼加拉瓜",
  //   countryEName: "Nicaragua",
  //   img: "Nicaragua.png",
  // },
  // {
  //   countryCode: "NE",
  //   countryName: "尼日尔",
  //   countryEName: "Niger",
  //   img: "Niger.png",
  // },
  {
    countryCode: "NG",
    countryName: "尼日利亚",
    countryEName: "Nigeria",
    img: "Nigeria.png",
  },
  // {
  //   countryCode: "NU",
  //   countryName: "纽爱",
  //   countryEName: "Niue",
  //   img: "Niue.jpg",
  // },
  // {
  //   countryCode: "NF",
  //   countryName: "诺福克岛",
  //   countryEName: "Norfolk Island",
  //   img: "Norfolk Island.jpg",
  // },
  // {
  //   countryCode: "KP",
  //   countryName: "朝鲜",
  //   countryEName: "North Korea",
  //   img: "North Korea.png",
  // },
  // {
  //   countryCode: "MK",
  //   countryName: "马其顿",
  //   countryEName: "North Macedonia",
  //   img: "Macedonia.png",
  // },
  // {
  //   countryCode: "MP",
  //   countryName: "北马里亚纳群岛",
  //   countryEName: "Northern Mariana Islands",
  //   img: "NorthernMarianaIsland.jpg",
  // },
  // {
  //   countryCode: "NO",
  //   countryName: "挪威",
  //   countryEName: "Norway",
  //   img: "Norway.png",
  // },
  // {
  //   countryCode: "OM",
  //   countryName: "阿曼",
  //   countryEName: "Oman",
  //   img: "Oman.png",
  // },
  {
    countryCode: "PK",
    countryName: "巴基斯坦",
    countryEName: "Pakistan",
    img: "Pakistan.png",
  },
  // {
  //   countryCode: "PW",
  //   countryName: "帕劳",
  //   countryEName: "Palau",
  //   img: "Palau.jpg",
  // },
  // {
  //   countryCode: "PA",
  //   countryName: "巴拿马",
  //   countryEName: "Panama",
  //   img: "Panama.png",
  // },
  // {
  //   countryCode: "PG",
  //   countryName: "巴布亚新几内亚",
  //   countryEName: "Papua New Guinea",
  //   img: "PapuaNewCuinea.jpg",
  // },
  // {
  //   countryCode: "PY",
  //   countryName: "巴拉圭",
  //   countryEName: "Paraguay",
  //   img: "Paraguay.png",
  // },
  // {
  //   countryCode: "PE",
  //   countryName: "秘鲁",
  //   countryEName: "Peru",
  //   img: "Peru.png",
  // },
  {
    countryCode: "PH",
    countryName: "菲律宾",
    countryEName: "Philippines",
    img: "Philippines.png",
  },
  // {
  //   countryCode: "PN",
  //   countryName: "皮特凯恩岛",
  //   countryEName: "Pitcairn Islands",
  //   img: "Pitcairn.png",
  // },
  // {
  //   countryCode: "PL",
  //   countryName: "波兰",
  //   countryEName: "Poland",
  //   img: "Poland.png",
  // },
  // {
  //   countryCode: "PS",
  //   countryName: "巴勒斯坦",
  //   countryEName: "Palestine",
  //   img: "Palestine.png",
  // },
  // {
  //   countryCode: "PT",
  //   countryName: "葡萄牙",
  //   countryEName: "Portugal",
  //   img: "Portugal.png",
  // },
  // {
  //   countryCode: "PR",
  //   countryName: "波多黎各",
  //   countryEName: "Puerto Rico",
  //   img: "Puerto Rico.png",
  // },
  // {
  //   countryCode: "QA",
  //   countryName: "卡塔尔",
  //   countryEName: "Qatar",
  //   img: "Qatar.png",
  // },
  // {
  //   countryCode: "RO",
  //   countryName: "罗马尼亚",
  //   countryEName: "Romania",
  //   img: "Romania.png",
  // },
  {
    countryCode: "RU",
    countryName: "俄罗斯",
    countryEName: "Russia",
    img: "Russia.png",
  },
  // {
  //   countryCode: "RW",
  //   countryName: "卢旺达",
  //   countryEName: "Rwanda",
  //   img: "Rwanda.png",
  // },
  // {
  //   countryCode: "KN",
  //   countryName: "圣基茨和尼维斯",
  //   countryEName: "Saint Kitts and Nevis",
  //   img: "Saint Kitts and Nevis.png",
  // },
  // {
  //   countryCode: "LC",
  //   countryName: "圣卢西亚",
  //   countryEName: "Saint Lucia",
  //   img: "SaintLueia.png",
  // },
  // {
  //   countryCode: "PM",
  //   countryName: "圣皮艾尔和密克隆群岛",
  //   countryEName: "Saint Pierre and Miquelon",
  //   img: "St.Pierreand Miquelon.png",
  // },
  // {
  //   countryCode: "VC",
  //   countryName: "圣文森特和格陵纳丁斯",
  //   countryEName: "Saint Vincent and the Grenadines",
  //   img: "Saint Vincent.png",
  // },
  // {
  //   countryCode: "SM",
  //   countryName: "圣马力诺",
  //   countryEName: "San Marino",
  //   img: "San Marino.png",
  // },
  // {
  //   countryCode: "ST",
  //   countryName: "圣多美和普林西比",
  //   countryEName: "Sao Tome and Principe",
  //   img: "Sao Tome and Principe.png",
  // },
  // {
  //   countryCode: "SA",
  //   countryName: "沙特阿拉伯",
  //   countryEName: "Saudi Arabia",
  //   img: "Saudi Arabia.png",
  // },
  // {
  //   countryCode: "SN",
  //   countryName: "塞内加尔",
  //   countryEName: "Senegal",
  //   img: "Senegal.png",
  // },
  // {
  //   countryCode: "SC",
  //   countryName: "塞舌尔",
  //   countryEName: "Seychelles",
  //   img: "Seychelles.png",
  // },
  // {
  //   countryCode: "SL",
  //   countryName: "塞拉利昂",
  //   countryEName: "Sierra Leone",
  //   img: "Sierra Leone.png",
  // },
  // {
  //   countryCode: "SG",
  //   countryName: "新加坡",
  //   countryEName: "Singapore",
  //   img: "Singapore.png",
  // },
  // {
  //   countryCode: "SK",
  //   countryName: "斯洛伐克",
  //   countryEName: "Slovakia",
  //   img: "Slovakia.png",
  // },
  // {
  //   countryCode: "SI",
  //   countryName: "斯罗文尼亚",
  //   countryEName: "Slovenia",
  //   img: "Slovenia.png",
  // },
  // {
  //   countryCode: "SB",
  //   countryName: "所罗门群岛",
  //   countryEName: "Solomon Islands",
  //   img: "SolomonIslands.jpg",
  // },
  // {
  //   countryCode: "SO",
  //   countryName: "索马里",
  //   countryEName: "Somalia",
  //   img: "Somalia.png",
  // },

  // {
  //   countryCode: "ZA",
  //   countryName: "南非",
  //   countryEName: "South Africa",
  //   img: "South Africa.png",
  // },
  // {
  //   countryCode: "KR",
  //   countryName: "韩国",
  //   countryEName: "South Korea",
  //   img: "Korea.png",
  // },
  // {
  //   countryCode: "ES",
  //   countryName: "西班牙",
  //   countryEName: "Spain",
  //   img: "Spain.png",
  // },
  // {
  //   countryCode: "LK",
  //   countryName: "斯里兰卡",
  //   countryEName: "Sri Lanka",
  //   img: "Sri Lanka.png",
  // },
  // {
  //   countryCode: "SD",
  //   countryName: "苏丹",
  //   countryEName: "Sudan",
  //   img: "Sudan.png",
  // },
  // {
  //   countryCode: "SR",
  //   countryName: "苏里南",
  //   countryEName: "Suriname",
  //   img: "Suriname.png",
  // },
  // {
  //   countryCode: "SE",
  //   countryName: "瑞典",
  //   countryEName: "Sweden",
  //   img: "Sweden.png",
  // },
  // {
  //   countryCode: "CH",
  //   countryName: "瑞士",
  //   countryEName: "Switzerland",
  //   img: "Switzerland.png",
  // },
  // {
  //   countryCode: "SY",
  //   countryName: "叙利亚",
  //   countryEName: "Syria",
  //   img: "Syria.png",
  // },
  // {
  //   countryCode: "TW",
  //   countryName: "中国台湾省",
  //   countryEName: "Taiwan",
  //   img: "Taiwan.jpg",
  // },
  // {
  //   countryCode: "TJ",
  //   countryName: "塔吉克斯坦",
  //   countryEName: "Tajikistan",
  //   img: "Tajikistan.png",
  // },
  // {
  //   countryCode: "TZ",
  //   countryName: "坦桑尼亚",
  //   countryEName: "Tanzania",
  //   img: "Tanzania.png",
  // },
  {
    countryCode: "TH",
    countryName: "泰国",
    countryEName: "Thailand",
    img: "Thailand.png",
  },
  // {
  //   countryCode: "TP",
  //   countryName: "东帝汶",
  //   countryEName: "Timor-Leste",
  //   img: "Timor.png",
  // },
  // {
  //   countryCode: "TG",
  //   countryName: "多哥",
  //   countryEName: "Togo",
  //   img: "Togo.png",
  // },
  // {
  //   countryCode: "TK",
  //   countryName: "托克劳群岛",
  //   countryEName: "Tokelau",
  //   img: "Tokelau.jpg",
  // },
  // {
  //   countryCode: "TO",
  //   countryName: "汤加",
  //   countryEName: "Tonga",
  //   img: "Tonga.jpg",
  // },
  // {
  //   countryCode: "TT",
  //   countryName: "特立尼达和多巴哥",
  //   countryEName: "Trinidad and Tobago",
  //   img: "Trinidad and Tobago.jpg",
  // },
  // {
  //   countryCode: "TN",
  //   countryName: "突尼斯",
  //   countryEName: "Tunisia",
  //   img: "Tunisia.png",
  // },
  {
    countryCode: "TR",
    countryName: "土耳其",
    countryEName: "Turkey",
    img: "Turkey.png",
  },
  // {
  //   countryCode: "TM",
  //   countryName: "土库曼斯坦",
  //   countryEName: "Turkmenistan",
  //   img: "Turkmenistan.png",
  // },
  // {
  //   countryCode: "TC",
  //   countryName: "特克斯和凯科斯群岛",
  //   countryEName: "Turks and Caicos Islands",
  //   img: "Turks and Caicos Islands.jpg",
  // },
  // {
  //   countryCode: "TV",
  //   countryName: "图瓦卢",
  //   countryEName: "Tuvalu",
  //   img: "Tuvalu.jpg",
  // },
  // {
  //   countryCode: "UG",
  //   countryName: "乌干达",
  //   countryEName: "Uganda",
  //   img: "Uganda.png",
  // },
  // {
  //   countryCode: "UA",
  //   countryName: "乌克兰",
  //   countryEName: "Ukraine",
  //   img: "Ukraine.png",
  // },
  // {
  //   countryCode: "AE",
  //   countryName: "阿联酋",
  //   countryEName: "United Arab Emirates",
  //   img: "United Arab Emirates.png",
  // },
  {
    countryCode: "GB",
    countryName: "英国",
    countryEName: "United Kingdom",
    img: "UnitedKiongdom.png",
  },
  {
    countryCode: "US",
    countryName: "美国",
    countryEName: "United States",
    img: "United States of America.png",
  },
  // {
  //   countryCode: "UY",
  //   countryName: "乌拉圭",
  //   countryEName: "Uruguay",
  //   img: "Uruguay.png",
  // },
  // {
  //   countryCode: "UZ",
  //   countryName: "乌兹别克斯坦",
  //   countryEName: "Uzbekistan",
  //   img: "Uzbekistan.png",
  // },
  // {
  //   countryCode: "VU",
  //   countryName: "瓦努阿鲁",
  //   countryEName: "Vanuatu",
  //   img: "Vanuatu.jpg",
  // },
  // {
  //   countryCode: "VA",
  //   countryName: "梵蒂岗",
  //   countryEName: "Vatican City",
  //   img: "Vatican City.png",
  // },
  // {
  //   countryCode: "VE",
  //   countryName: "委内瑞拉",
  //   countryEName: "Venezuela",
  //   img: "Venezuela.jpg",
  // },
  {
    countryCode: "VN",
    countryName: "越南",
    countryEName: "Vietnam",
    img: "Vietnam.png",
  },
  // {
  //   countryCode: "YE",
  //   countryName: "也门",
  //   countryEName: "Yemen",
  //   img: "Yemen.png",
  // },
  // {
  //   countryCode: "ZM",
  //   countryName: "赞比亚",
  //   countryEName: "Zambia",
  //   img: "Zambia.png",
  // },
  // {
  //   countryCode: "ZW",
  //   countryName: "津巴布韦",
  //   countryEName: "Zimbabwe",
  //   img: "Zimbabwe.png",
  // },
];

// { countryCode: "FX", countryName: "大都会" },
// { countryCode: "GP", countryName: "法属德洛普群岛" },
// { countryCode: "HM", countryName: "赫德和麦克唐纳群岛" },
// { countryCode: "MQ", countryName: "法属马提尼克群岛" },
// { countryCode: "MM", countryName: "缅甸" },
// { countryCode: "NT", countryName: "中立区（沙特-伊拉克间）" },
// { countryCode: "GS", countryName: "Isls." },
// { countryCode: "SH", countryName: "海伦娜" },
// { countryCode: "SJ", countryName: "斯瓦尔巴特和扬马延岛" },
// { countryCode: "UM", countryName: "美国海外领地" },
// { countryCode: "SU", countryName: "前苏联" },
// { countryCode: "VG", countryName: "英属维京群岛" },
// { countryCode: "VI", countryName: "美属维京群岛" },
// { countryCode: "WF", countryName: "瓦里斯和福图纳群岛" },
// { countryCode: "EH", countryName: "西撒哈拉" },
// { countryCode: "YU", countryName: "南斯拉夫" },
// { countryCode: "ZR", countryName: "扎伊尔" },
// { countryCode: "AQ", countryName: "南极洲" },
