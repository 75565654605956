<template>
  <div class="main-left">
    <!-- 网站导航类型 -->

    <div class="d-flex flex-fill customize-menu">
      <!-- <button class="arrow-button btn btn-sm" @click="scrollTabs(-1)">
        <van-icon name="arrow-left" />
      </button> -->
      <van-icon
        name="coupon-o"
        size="1.5rem"
        class="me-2 my-auto"
        style="color: #888"
      />
      <div
        class="slider_menu mini_tab intcustomize-menuo"
        slidertab="sliderTab"
        ref="tabsWrapper"
      >
        <ul
          class="nav nav-pills tab-auto-scrollbar menu"
          role="tablist"
          ref="tabs"
        >
          <!-- <li
            class="anchor"
            style="
              position: absolute;
              width: 80px;
              height: 28px;
              left: 0px;
              opacity: 1;
            "
          ></li> -->
          <li
            class="nav-item"
            v-for="(item, key) in websitesTypes"
            :key="key"
            :class="{ 'active-tab': selectedType === item.name }"
            @click="selectType(item.name)"
          >
            <a class="nav-link" data-toggle="pill">{{
              $t("WebsitesType." + item.name)
            }}</a>
          </li>
        </ul>
      </div>
      <!-- <button class="arrow-button btn btn-sm" @click="scrollTabs(1)">
        <van-icon name="arrow" />
      </button> -->
    </div>
    <!-- <div class="tabs-container d-flex align-items-center mt-3">
      <button class="arrow-button btn btn-sm" @click="scrollTabs(-1)">
        <van-icon name="arrow-left" />
      </button>
      <div class="tabs-wrapper flex-grow-1 overflow-hidden" ref="tabsWrapper">
        <div class="tabs d-flex" ref="tabs">
          <div
            class="tab p-2"
            v-for="(item, key) in websitesTypes"
            :key="key"
            :class="{ 'active-tab': selectedType === item.name }"
            @click="selectType(item.name)"
          >
            {{ $t("WebsitesType." + item.name) }}
          </div>
        </div>
      </div>
      <button class="arrow-button btn btn-sm" @click="scrollTabs(1)">
        <van-icon name="arrow" />
      </button>
    </div> -->
    <hr />
    <div id="cust-sites" class="tab-content mt-4">
      <div id="my-nav" class="tab-pane active">
        <div class="row io-mx-n2">
          <!-- col-6 col-lg-3  -->
          <div
            class="url-card col-6 col-md-3 col-lg-2"
            v-for="(item, key) in websList"
            :key="key"
          >
            <div class="url-body mini">
              <a
                :href="item.link"
                data-id="294"
                class="card mb-3 site-294"
                data-toggle="tooltip"
                data-placement="bottom"
                :title="item.name"
              >
                <div class="card-body">
                  <div class="url-content d-flex align-items-center">
                    <div
                      class="url-img rounded-circle me-2 d-flex align-items-center justify-content-center"
                    >
                      <img
                        class="lazy unfancybox loaded"
                        :src="item.imgUrl"
                        height="auto"
                        width="auto"
                        :alt="item.name"
                        data-was-processed="true"
                      />
                    </div>
                    <div class="url-info flex-fill" style="padding-top: 2px">
                      <div class="text-sm overflowClip_1">
                        <strong>{{ item.name }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- <div id="my-click" class="tab-pane">
        <div class="row io-mx-n2 my-click-list">
          <div class="url-card col-6 col-lg-3">
            <div class="url-body mini">
              <a
                href="https://undraw.co/illustrations"
                target="_blank"
                class="card new-site mb-3 site-819"
                data-id="819"
                data-url="https://undraw.co/illustrations"
                data-toggle="tooltip"
                data-placement="bottom"
                title="unDraw"
                rel="external nofollow"
                ><div class="card-body" style="padding: 0.4rem 0.5rem">
                  <div class="url-content d-flex align-items-center">
                    <div
                      class="url-img rounded-circle me-2 d-flex align-items-center justify-content-center"
                    >
                      <img src="https://api.iowen.cn/favicon/undraw.co.png" />
                    </div>
                    <div class="url-info flex-fill">
                      <div class="text-sm overflowClip_1">
                        <strong>unDraw</strong>
                      </div>
                    </div>
                  </div>
                </div></a
              >
            </div>
            <a
              href="javascript:;"
              class="text-center remove-site"
              data-id="819"
              style="display: none"
              ><i class="iconfont icon-close-circle"></i
            ></a>
          </div>
        </div>
      </div> -->
    </div>
    <!-- 网站链接 -->
    <!-- <div class="container">
      <div class="row">
        <div
          class="col-4 col-md-4 col-lg-6 mb-4 d-flex justify-content-center"
          v-for="(item, key) in websList"
          :key="key"
        >
          <a :href="item.link" class="website">
            {{ item.name }}
          </a>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { websitesType } from "@/data/Type";
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
export default {
  name: "tabComponent-Vue",
  components: {},
  // props: {
  //   tabs: {
  //     type: Array,
  //     required: true,
  //   },
  // },
  setup() {
    const store = useStore();
    const selectedType = ref(null);
    const websList = computed(() => store.getters["Websites/websitesList"]);
    const countryCode = computed(() => store.getters["Home/country"]);
    const websitesTypes = websitesType;
    const webList = ref([
      { link: "https://www.baidu.com" },
      { link: "https://www.baidu.com" },
      { link: "https://www.baidu.com" },
      { link: "https://www.baidu.com" },
      { link: "https://www.baidu.com" },
      { link: "https://www.baidu.com" },
      { link: "https://www.baidu.com" },
      { link: "https://www.baidu.com1231431243091834918290" },
      { link: "https://www.baidu.com" },
      { link: "https://www.baidu.com1231431243091834918290" },
    ]);
    const selectType = (value) => {
      selectedType.value = value;
      const cCode = countryCode.value;
      store.dispatch("Websites/getWebsitesByTC", {
        websiteType: value,
        countryCode: cCode,
      });
    };

    watch(
      () => [countryCode.value],
      () => {
        selectType(selectType.value);
      }
    );
    onMounted(() => {
      selectType("portal");
    });
    return { selectedType, websitesTypes, websList, webList, selectType };
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  methods: {
    truncate(value, length = 20) {
      if (!value) return "";
      value = value.replace(/https:\/\/+/, "");
      // console.log(value);
      return value.length > length ? value.slice(0, length) + "..." : value;
    },
    scrollTabs(direction) {
      const tabsWrapper = this.$refs.tabsWrapper; //父容器
      const tabs = this.$refs.tabs; //子容器
      const tabWidth = tabs.children[0].offsetWidth; //子容器的宽度
      const visibleTabsCount = Math.floor(tabsWrapper.clientWidth / tabWidth); //父容器宽度/子容器宽度，一行能放几个
      const maxIndex = Math.floor(tabs.children.length / visibleTabsCount);
      this.currentIndex = Math.max(
        0,
        Math.min(maxIndex, this.currentIndex + direction)
      );
      const offset = -this.currentIndex * visibleTabsCount * tabWidth;
      tabs.style.transform = `translateX(${offset}px)`;
    },
  },
};
</script>

<style scoped lang="less">
.tabs-container {
  width: 100%;
  display: flex;
  align-items: center;
}
/* .arrow-button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  user-select: none;
} */
.website {
  text-align: left;
  font-size: 1rem;
}
.tabs-wrapper {
  overflow: hidden;
  white-space: nowrap;
}

.link {
  color: black;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.link:hover,
.link:focus {
  color: rgb(22, 20, 142);
  text-decoration: underline;
}
.tabs {
  display: flex;
  transition: transform 0.3s ease;
}

.tab {
  flex: 0 0 auto;
  /* background: #ddd; */
  padding: 0 0.1rem;
  border-radius: 0.1rem;
}
/* .. */
// .flex-fill {
//   -ms-flex: 1 1 auto !important;
//   flex: 1 1 auto !important;
// }
// .d-flex {
//   display: -ms-flexbox !important;
//   display: flex !important;
// }
.main-left {
  width: 100%;
  background-color: rgb(249, 249, 249);
}
.customize-menu {
  padding: 1rem 0;
}
*,
::after,
::before {
  box-sizing: border-box;
}
.slider_menu[sliderTab] {
  position: relative;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  border: 3px solid transparent;
  overflow: hidden;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  ul {
    margin-top: 0;
    overflow-y: auto;
    white-space: nowrap;
    .nav {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
    }
  }
  .menu {
    position: relative;
    white-space: nowrap;
    flex-wrap: initial;

    li {
      display: list-item;
      text-align: -webkit-match-parent;
      unicode-bidi: isolate;
      cursor: pointer;
      width: auto;
      height: auto;

      a {
        height: 28px;
        line-height: 28px;
        padding: 0 12px;
        display: block;
        text-decoration: none;
        position: relative;
        color: #888;
        // height: 40px;
        // line-height: 40px;
        text-align: center;
        font-size: 1rem;
        // padding: 0 16px;
        -webkit-transition: 0.25s;
        transition: 0.25s;
      }
      a:hover {
        color: #fff;
      }
      a:active {
        color: #fff;
      }
    }
    .active-tab {
      color: #fff;
      background: #f1404b;
      border-radius: 100px;
      // width: 60px;
      // height: 40px;
      transition: 0.73s;
      a {
        color: #fff;
      }
    }
  }
  .anchor {
    background: #f1404b;
    border-radius: 100px;
    // width: 60px;
    // height: 40px;
    opacity: 0;
    -webkit-transition: 0.35s;
    transition: 0.35s;
  }

  .nav-pills.menu .nav-link.active,
  .nav-pills.menu .show > .nav-link {
    background: none;
  }
  .nav-pills .nav-link {
    border-radius: 0.25rem;
  }
}

.tab-content {
  .url-card {
    .url-info {
      overflow: hidden;
      padding-right: 5px;
      .overflowClip_1 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
      .overflowClip_1 {
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        // -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .text-sm {
        font-size: 1rem !important;
      }
    }
    .url-body {
      transition: transform 0.3s ease;
      a {
        outline: 0 !important;
        text-decoration: none;
      }
    }
    .url-body :hover {
      border-color: #f1404b;
      // transform: translateY(-5px);
    }
    .mini .url-img {
      width: 25px;
      height: 25px;
    }
    .url-img {
      width: 40px;
      height: 40px;
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      background: rgba(128, 128, 128, 0.1);
      overflow: hidden;

      img {
        max-height: 100%;
        vertical-align: unset;
      }
    }
  }
}
</style>
