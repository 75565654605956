<template>
  <div
    class="auto-ad-url text-center my-4 mx-2 mb-n4 mb-md-n5 position-relative z-index-1"
  >
    <div class="card my-0 mx-auto io-radius">
      <div class="card-head d-flex align-items-center pb-0 px-2 pt-2">
        <div class="text-sm"><van-icon name="fire" class="me-2" />热门</div>
      </div>
      <div class="card-body pt-1 pb-1 px-2 row row-xs">
        <div
          class="col-3a col-md-6a my-1"
          v-for="(item, key) in topWebsList"
          :key="key"
        >
          <a
            :href="item.link"
            class="d-flex align-items-center auto-url-list io-radius px-2 py-1"
            target="_blank"
            rel="external"
            :title="item.name"
            ><div class="auto-ad-img rounded-circle overflow-hidden">
              <img :src="item.imgUrl" :alt="item.name" />
            </div>
            <div class="auto-ad-name text-sm ms-1 ms-md-2 overflowClip_1">
              {{ item.name }}
            </div></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
export default {
  name: "home2-Vue",
  components: {},
  methods: {},
  setup() {
    const store = useStore();
    const topWebsList = computed(
      () => store.getters["TopWebsites/topWebsitesList"]
    );
    const countryCode = computed(() => store.getters["Home/country"]);
    const itemlinkList = ref([
      {
        id: "123",
        img: "https://img2.baidu.com/it/u=3335976388,3387344120&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1721754000&t=0a94f55197fa64c230a3275924c04305",
        name: "百度",
        link: "https://x.com/Beverlyrsri/status/1806580622011342961?s=19",
      },
      {
        id: "123",
        img: "https://img2.baidu.com/it/u=3335976388,3387344120&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1721754000&t=0a94f55197fa64c230a3275924c04305",
        name: "百度",
        link: "https://www.baidu.com/",
      },
      {
        id: "123",
        img: "https://img2.baidu.com/it/u=3335976388,3387344120&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1721754000&t=0a94f55197fa64c230a3275924c04305",
        name: "百度",
        link: "https://www.baidu.com/",
      },
      {
        id: "123",
        img: "https://img2.baidu.com/it/u=3335976388,3387344120&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1721754000&t=0a94f55197fa64c230a3275924c04305",
        name: "百度",
        link: "https://www.baidu.com/",
      },
      {
        id: "123",
        img: "https://img2.baidu.com/it/u=3335976388,3387344120&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1721754000&t=0a94f55197fa64c230a3275924c04305",
        name: "百度",
        link: "https://www.baidu.com/",
      },
      {
        id: "123",
        img: "https://img2.baidu.com/it/u=3335976388,3387344120&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1721754000&t=0a94f55197fa64c230a3275924c04305",
        name: "百度",
        link: "https://www.baidu.com/",
      },
      {
        id: "123",
        img: "https://img2.baidu.com/it/u=3335976388,3387344120&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1721754000&t=0a94f55197fa64c230a3275924c04305",
        name: "百度",
        link: "https://www.baidu.com/",
      },
    ]);
    const sendGetTopWebsites = () => {
      const cCode = countryCode.value;
      store.dispatch("TopWebsites/getLastWebsitesByC", {
        websiteType: null,
        countryCode: cCode,
      });
    };
    watch(
      () => [countryCode.value],
      () => {
        sendGetTopWebsites();
      }
    );
    onMounted(() => {
      sendGetTopWebsites();
    });
    return { itemlinkList, topWebsList };
  },
};
</script>

<style lang="less">
.item-img {
  width: 3rem;
  height: 3rem;
}
.horizontal-scroll {
  overflow-y: auto; /* 启用滚动 */
  white-space: nowrap; /* 防止内容换行 */
}
.horizontal-scroll .nav-item {
  display: inline-block; /* 将每个导航项设为内联块级元素 */
}
.bg-transparent-80 {
  background-color: rgb(231, 217, 156); /* 红色背景透明度为50% */
}

.auto-ad-url {
  --main-border-color: rgba(255, 255, 255, 0.1);
  --muted-border-color: rgba(10, 12, 15, 0.3);
  .card {
    color: #eee;
    background: rgba(100, 100, 100, 0.3);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }

  // .io-radius {
  //   border-radius: var(--main-radius) !important;
  // }
  .card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    .col-3a {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    @media (min-width: 768px) {
      .col-md-6a {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
      }
    }
  }
  .auto-url-list {
    background: var(--muted-border-color);
  }
  a {
    color: #fff;
    outline: 0 !important;
    text-decoration: none;
  }
  .auto-ad-img {
    width: 25px;
    height: 25px;
    // line-height: 14px;
    // vertical-align: unset;

    img {
      vertical-align: middle;
      border-style: none;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .overflow-hidden {
    overflow: hidden !important;
  }
  .rounded-circle {
    border-radius: 50% !important;
  }
  .row-xs {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  @media (max-width: 767.98px) {
    .row [class*="col-"] {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
  .row-xs [class*="col-"],
  .row-xs .col {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .overflowClip_1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}
.position-relative {
  position: relative !important;
}
*,
::after,
::before {
  box-sizing: border-box;
}
.io-radius {
  border-radius: var(--main-radius) !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}


// @media (min-width: 768px) {
//   .auto-ad-url .auto-ad-img {
//     width: 21px;
//     min-width: 21px;
//     height: 21px;
//     line-height: 21px;
//   }
// }
</style>
