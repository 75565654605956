<template>
  <div
    class="container-fluid pb-3"
    style="background-color: rgb(249, 249, 249)"
  >
    <Left></Left>
    <br />
    <div class="container" style="width: 100px">
      <div
        class="offcanvas offcanvas-start"
        tabindex="-1"
        id="offcanvasExample3"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel"></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body container">
          <ul class="nav d-flex flex-column justify-content-center">
            <li class="nav-item mb-1">
              <van-icon name="label-o" size="1.5rem" />123
            </li>
          </ul>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
//import Right from "./homemainRight.vue";
import Left from "./homemainLeft.vue";
export default {
  name: "homemain-Vue",
  components: {
    //Right,
    Left,
  },
  setup() {
    const tags = ref();
    const { t } = useI18n();
    const selectTag = (id) => {
      console.log(id);
    };

    const valuesArray = (obj) => {
      return Object.values(obj).map((value, index) => ({
        id: index,
        name: value,
      }));
    };
    const loadTags = () => {
      tags.value = t("type");
      console.log(tags.value);
    };

    onMounted(() => {
      loadTags();
    });
    return { tags, valuesArray, selectTag };
  },
};
</script>

<style lang="less">
.main {
  width: 80%;
}
.bg-transparent-60 {
  background-color: rgba(244, 194, 15, 0.956); /* 红色背景透明度为50% */
}
.type {
  overflow-y: auto; /* 启用滚动 */
  white-space: nowrap; /* 防止内容换行 */
}
.type .nav-item {
  display: inline-block; /* 将每个导航项设为内联块级元素 */
}


</style>
