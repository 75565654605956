import axios from "axios";
import nprogress from "nprogress";
import { ElMessage } from "element-plus";
import "nprogress/nprogress.css";
const http = axios.create({
  baseURL: "https://ab.netweb.link",
  timeout: 10000,
  //baseURL: "http://localhost:8088",
});

http.interceptors.request.use(
  (config) => {
    nprogress.start();
    let user = window.sessionStorage.getItem("user");
    console.log(JSON.parse(user));
    if (user) {
      let token = JSON.parse(user).token;
      console.log(token);
      config.headers.token = token;
    }
    return config;
  },
  (err) => {
    console.log(err);
  }
);
http.interceptors.response.use(
  (response) => {
    nprogress.done();
    if (response.status === 401) {
      sessionStorage.removeItem("user");
      window.location.href = "http://netweb.link";
    }
    return response.data;
  },
  (err) => {
    if (err.response.status === 401) {
      sessionStorage.removeItem("user");
      ElMessage.error(this.$t("Home.login_expired"));
      //ElMessage.error("登录失效，请重新登录");
      setTimeout(() => {
        window.location.href = "http://netweb.link";
      }, 400);
    }
    console.log(err);
  }
);
export default http;
