<template>
  <div class="main-right">
    <div class="d-flex flex-fill customize-menu">
      <router-link class="my-auto" to="/home1">
        <van-icon name="wap-home-o" size="1.5rem" class="my-auto me-2 home1" />
      </router-link>
      <div
        class="slider_menu mini_tab intcustomize-menuo"
        slidertab="sliderTab"
      >
        <ul class="nav nav-pills tab-auto-scrollbar menu" role="tablist">
          <li
            class="nav-item"
            :class="{ 'active-tab': selectedType === 'recommended' }"
            @click="selectType('recommended')"
          >
            <a class="nav-link" data-toggle="pill">
              {{ $t("NewType.recommended") }}</a
            >
          </li>
          <li
            class="nav-item"
            v-for="(item, key) in newTypes"
            :key="key"
            :class="{ 'active-tab': selectedType === item.name }"
            @click="selectType(item.name)"
          >
            <a class="nav-link" data-toggle="pill">{{
              $t("NewType." + item.name)
            }}</a>
          </li>
        </ul>
      </div>
      <!-- <button class="arrow-button btn btn-sm" @click="scrollTabs(1)">
        <van-icon name="arrow" />
      </button> -->
    </div>
    <div class="d-flex flex-fill d-none d-md-flex py-2 scroll-container">
      <div class="newitem nav-item" v-for="(item, key) in newsList" :key="key">
        <img class="image my-2" :src="item.imgUrl" />
        <div class="text-start item-text flex-column">
          <a class="href" @click="toNewsDetail(key)"
            ><h5>
              {{ item.title }}
            </h5></a
          >
          <p class="text-secondary">{{ item.content }}</p>
          <p class="text-secondary mt-auto">
            <a class="href" :href="item.authorLink"
              ><span>{{ item.author }}</span></a
            >
            <span v-if="item.author" class="me-2"></span>
            <span>{{ formatTime(item) }}</span>
          </p>
        </div>
        <div
          v-if="item.userId"
          class="row justify-content-start text-secondary"
        >
          <span class="col-3 col-lg-1"
            ><van-icon name="eye-o" /> {{ item.look }}</span
          ><span class="col-3 col-lg-1"
            ><van-icon name="star-o" class="me-1" />{{ item.collect }}</span
          >
        </div>
      </div>
      <div v-if="true" ref="loader" class="newitem nav-item">
        <div class="my-2">
          <h1
            class="image placeholder col-12 bg-secondary"
            style="width: 310px; height: 200px"
          ></h1>
        </div>
        <div class="col-12">
          <div class="text-start item-text d-flex flex-column">
            <h3 class="card-title placeholder-glow">
              <span class="placeholder col-12"></span>
            </h3>
            <p class="placeholder-glow mt-2">
              <span class="placeholder bg-secondary col-12"></span>
              <span class="placeholder bg-secondary col-12"></span>
            </p>
            <p class="placeholder-glow mt-auto">
              <span class="placeholder col-4 me-2 bg-secondary"></span>
              <span class="placeholder col-4 bg-secondary"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <van-swipe
      :show-indicators="false"
      :loop="false"
      class="d-md-none d-flex my-2"
      @change="onChange"
    >
      <van-swipe-item
        class="newitem2 nav-item"
        v-for="(item, key) in newsList"
        :key="key"
      >
        <img class="image my-2" :src="item.imgUrl" />
        <div class="text-start item-text flex-column">
          <a class="href" @click="toNewsDetail(key)"
            ><h5>
              {{ item.title }}
            </h5></a
          >
          <p class="text-secondary">{{ item.content }}</p>
          <p class="text-secondary mt-auto">
            <a class="href" :href="item.authorLink"
              ><span>{{ item.author }}</span></a
            >
            <span v-if="item.author" class="me-2"></span>
            <span>{{ formatTime(item) }}</span>
          </p>
        </div></van-swipe-item
      >
      <van-swipe-item v-if="true" class="newitem2 nav-item">
        <div class="my-2">
          <h1
            class="image placeholder col-12 bg-secondary"
            style="height: 180px"
          ></h1>
        </div>
        <div class="col-12">
          <div class="text-start item-text d-flex flex-column">
            <h3 class="card-title placeholder-glow">
              <span class="placeholder col-12"></span>
            </h3>
            <p class="placeholder-glow mt-2">
              <span class="placeholder bg-secondary col-12"></span>
              <span class="placeholder bg-secondary col-12"></span>
            </p>
            <p class="placeholder-glow mt-auto">
              <span class="placeholder col-4 me-2 bg-secondary"></span>
              <span class="placeholder col-4 bg-secondary"></span>
            </p>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { newType } from "@/data/Type";
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  name: "homemainTag-Vue",
  components: {},
  setup() {
    const newTypes = newType;
    const router = useRouter();
    const store = useStore();
    const selectedType = ref("recommended");
    //const isReSelectType = ref(false);
    const observer = ref(null);
    const loader = ref(null);
    const countryCode = computed(() => store.getters["Home/country"]);
    const languageCode = computed(() => store.getters["Home/language"]);
    const newsList = computed(() => store.getters["Home2/newsList"]);
    const onChange = (index) => {
      if (index === newsList.value.length) {
        sendGetNewsList(0, selectedType.value);
      }
    };
    //选择新闻类型
    const selectType = (value) => {
      selectedType.value = value;
    };
    //后端请求
    const sendGetNewsList = (page, type) => {
      const cCode = countryCode.value;
      const lCode = languageCode.value;
      if (type == "recommended") {
        const user = sessionStorage.getItem("user");
        console.log(user);
        if (user) {
          store.dispatch("Home2/getNewsByUserNewType", {
            page,
            userId: JSON.parse(user).userId,
            languageCode: lCode,
            countryCode: cCode,
            isUser: "true",
          });
        } else {
          store.dispatch("Home2/getNewsbyTLC", {
            page,
            newType: null,
            languageCode: lCode,
            countryCode: cCode,
            isUser: "true",
          });
        }
      } else {
        store.dispatch("Home2/getNewsbyTLC", {
          page,
          newType: type,
          languageCode: lCode,
          countryCode: cCode,
          isUser: "true",
        });
      }
    };
    const createObserver = () => {
      const options = {
        root: null, // 使用视口作为容器
        rootMargin: "0px",
        threshold: 0.6, // 当加载组件 100% 进入视口时触发回调
      };
      observer.value = new IntersectionObserver(handleIntersect, options);
      observer.value.observe(loader.value);
    };
    const handleIntersect = (entries) => {
      if (entries[0].isIntersecting) {
        //加载数据
        sendGetNewsList(0, selectedType.value);
      }
    };
    //规范化时间
    const formatTime = (item) => {
      if (item !== null) {
        const timestamp = item.time;
        const date = new Date(timestamp);

        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // 月份从0开始，需要加1
        const day = String(date.getUTCDate()).padStart(2, "0");
        const hours = String(date.getUTCHours()).padStart(2, "0");
        const minutes = String(date.getUTCMinutes()).padStart(2, "0");
        const seconds = String(date.getUTCSeconds()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
      } else {
        return;
      }
    };
    //跳转新闻页
    const toNewsDetail = (key) => {
      if (newsList.value[key].userId) {
        store.dispatch("Home/addLook", { newId: newsList.value[key].id });
      }
      store.commit("Home2/setType", selectedType.value);
      router.push({ path: "/navFrame2", query: { key: key } });
    };

    watch(
      () => [languageCode.value, countryCode.value, selectedType.value],
      () => {
        sendGetNewsList(1, selectedType.value);
      }
    );
    onMounted(() => {
      createObserver();
    });
    return {
      newsList,
      selectedType,
      newTypes,
      selectType,
      formatTime,
      toNewsDetail,
      loader,
      onChange,
    };
  },
};
</script>

<style lang="less"></style>
