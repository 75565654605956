<template>
  <div class="main-right">
    <!-- 新闻信息分类 -->
    <div class="d-flex flex-fill customize-menu">
      <router-link class="my-auto" to="/home2">
        <van-icon name="eye-o" size="1.5rem" class="my-auto me-2 home1" />
      </router-link>
      <div
        class="slider_menu mini_tab intcustomize-menuo"
        slidertab="sliderTab"
      >
        <ul class="nav nav-pills tab-auto-scrollbar menu" role="tablist">
          <li
            class="nav-item"
            :class="{ 'active-tab': selectedType === 'recommended' }"
            @click="selectType('recommended')"
          >
            <a class="nav-link" data-toggle="pill">
              {{ $t("NewType.recommended") }}</a
            >
          </li>
          <li
            class="nav-item"
            v-for="(item, key) in newTypes"
            :key="key"
            :class="{ 'active-tab': selectedType === item.name }"
            @click="selectType(item.name)"
          >
            <a class="nav-link" data-toggle="pill">{{
              $t("NewType." + item.name)
            }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="d-flex d-none d-md-flex py-2 scroll-container">
      <div class="newitem nav-item" v-for="(item, key) in newsList" :key="key">
        <img class="image my-2" :src="item.imgUrl" />
        <div class="text-start item-text flex-column">
          <a class="href" @click="toNewsDetail(key)"
            ><h5>
              {{ item.title }}
            </h5></a
          >
          <p class="text-secondary">{{ item.content }}</p>
          <p class="text-secondary mt-auto">
            <a class="href" :href="item.authorLink"
              ><span>{{ item.author }}</span></a
            >
            <span v-if="item.author" class="me-2"></span>
            <span>{{ formatTime(item) }}</span>
          </p>
        </div>
        <div
          v-if="item.userId"
          class="row justify-content-start text-secondary"
        >
          <span class="col-3 col-lg-1"
            ><van-icon name="eye-o" /> {{ item.look }}</span
          ><span class="col-3 col-lg-1"
            ><van-icon name="star-o" class="me-1" />{{ item.collect }}</span
          >
        </div>
      </div>
      <div v-if="true" ref="loader1" class="newitem nav-item">
        <div class="my-2">
          <h1
            class="image placeholder col-12 bg-secondary"
            style="width: 310px; height: 200px"
          ></h1>
        </div>
        <div class="col-12">
          <div class="text-start item-text d-flex flex-column">
            <h3 class="card-title placeholder-glow">
              <span class="placeholder col-12"></span>
            </h3>
            <p class="placeholder-glow mt-2">
              <span class="placeholder bg-secondary col-12"></span>
              <span class="placeholder bg-secondary col-12"></span>
            </p>
            <p class="placeholder-glow mt-auto">
              <span class="placeholder col-4 me-2 bg-secondary"></span>
              <span class="placeholder col-4 bg-secondary"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <van-swipe
      :show-indicators="false"
      :loop="false"
      class="d-md-none d-flex my-2"
      @change="onChange"
    >
      <van-swipe-item
        class="newitem2 nav-item"
        v-for="(item, key) in newsList"
        :key="key"
      >
        <img class="image my-2" :src="item.imgUrl" />
        <div class="text-start item-text flex-column">
          <a class="href" @click="toNewsDetail(key)"
            ><h5>
              {{ item.title }}
            </h5></a
          >
          <p class="text-secondary">{{ item.content }}</p>
          <p class="text-secondary mt-auto">
            <a class="href" :href="item.authorLink"
              ><span>{{ item.author }}</span></a
            >
            <span v-if="item.author" class="me-2"></span>
            <span>{{ formatTime(item) }}</span>
          </p>
        </div></van-swipe-item
      >
      <van-swipe-item v-if="true" class="newitem2 nav-item">
        <div class="my-2">
          <h1
            class="image placeholder col-12 bg-secondary"
            style="height: 180px"
          ></h1>
        </div>
        <div class="col-12">
          <div class="text-start item-text d-flex flex-column">
            <h3 class="card-title placeholder-glow">
              <span class="placeholder col-12"></span>
            </h3>
            <p class="placeholder-glow mt-2">
              <span class="placeholder bg-secondary col-12"></span>
              <span class="placeholder bg-secondary col-12"></span>
            </p>
            <p class="placeholder-glow mt-auto">
              <span class="placeholder col-4 me-2 bg-secondary"></span>
              <span class="placeholder col-4 bg-secondary"></span>
            </p>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { newType } from "@/data/Type";
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "homemainTag-Vue",
  components: {},
  setup() {
    const newTypes = newType;
    const router = useRouter();
    const store = useStore();
    const selectedType = ref("recommended");
    //const isReSelectType = ref(false);
    const observer1 = ref(null);
    // const observer2 = ref(null);
    const loader1 = ref(null);
    // const loader2 = ref(null);
    const countryCode = computed(() => store.getters["Home/country"]);
    const languageCode = computed(() => store.getters["Home/language"]);
    const newsList = computed(() => store.getters["Home/newsList"]);
    // const newsList = ref([
    //   {
    //     id: 1,
    //     userId: null,
    //     title: "数看75载现代农业谱新篇",
    //     content:
    //       "央视网消息：新中国成立75年来，我国农业经济稳步提升，产业结构优化升级，粮食生产稳定发展，粮食安全保障有力。",
    //     imgUrl:
    //       "https://dgss1.baidu.com/6ONXsjip0QIZ8tyhnq/it/u=2838343979,246096943&fm=30&app=106&f=JPEG?w=312&h=208&s=B991C91176F874214EA159640300E032",
    //     link: "123",
    //     author: "光明网",
    //     authorLink: "123",
    //     time: 1631116800,
    //   },
    //   {
    //     id: 1,
    //     userId: null,
    //     title: "数看75载现代农业谱新篇",
    //     content: "央视",
    //     imgUrl:
    //       "https://dgss0.baidu.com/6ONWsjip0QIZ8tyhnq/it/u=1985038684,245905629&fm=30&app=106&f=JPEG?w=312&h=208&s=92B1D0A66007B2EC46BBD51403005089",
    //     link: "123",
    //     author: "光明网",
    //     authorLink: "123",
    //     time: 1631116800,
    //   },
    //   {
    //     id: 1,
    //     userId: null,
    //     title: "数看75载现代农业谱新篇",
    //     content:
    //       "央视网消息：新中国成立75年来，我国农业经济稳步提升，产业结构优化升级，粮食生产稳定发展，粮食安全保障有力。",
    //     imgUrl:
    //       "https://dgss1.baidu.com/6ONXsjip0QIZ8tyhnq/it/u=2838343979,246096943&fm=30&app=106&f=JPEG?w=312&h=208&s=B991C91176F874214EA159640300E032",
    //     link: "123",
    //     author: "光明网",
    //     authorLink: "123",
    //     time: 1631116800,
    //   },
    //   {
    //     id: 1,
    //     userId: null,
    //     title: "数看75载现代农业谱新篇",
    //     content:
    //       "央视网消息：新中国成立75年来，我国农业经济稳步提升，产业结构优化升级，粮食生产稳定发展，粮食安全保障有力。",
    //     imgUrl:
    //       "https://dgss1.baidu.com/6ONXsjip0QIZ8tyhnq/it/u=2838343979,246096943&fm=30&app=106&f=JPEG?w=312&h=208&s=B991C91176F874214EA159640300E032",
    //     link: "123",
    //     author: "光明网",
    //     authorLink: "123",
    //     time: 1631116800,
    //   },
    //   {
    //     id: 1,
    //     userId: null,
    //     title: "数看75载现代农业谱新篇",
    //     content:
    //       "央视网消息：新中国成立75年来，我国农业经济稳步提升，产业结构优化升级，粮食生产稳定发展，粮食安全保障有力。",
    //     imgUrl:
    //       "https://dgss1.baidu.com/6ONXsjip0QIZ8tyhnq/it/u=2838343979,246096943&fm=30&app=106&f=JPEG?w=312&h=208&s=B991C91176F874214EA159640300E032",
    //     link: "123",
    //     author: "光明网",
    //     authorLink: "123",
    //     time: 1631116800,
    //   },
    // ]);
    //选择新闻类型
    const selectType = (value) => {
      selectedType.value = value;
    };
    const onChange = (index) => {
      if (index === newsList.value.length) {
        sendGetNewsList(0, selectedType.value);
      }
    };
    //后端请求
    const sendGetNewsList = (page, type) => {
      const cCode = countryCode.value;
      const lCode = languageCode.value;
      if (type == "recommended") {
        const user = sessionStorage.getItem("user");
        console.log(user);
        if (user) {
          store.dispatch("Home/getNewsByUserNewType", {
            page,
            userId: JSON.parse(user).userId,
            languageCode: lCode,
            countryCode: cCode,
          });
        } else {
          store.dispatch("Home/getNewsbyTLC", {
            page,
            newType: null,
            languageCode: lCode,
            countryCode: cCode,
          });
        }
      } else {
        store.dispatch("Home/getNewsbyTLC", {
          page,
          newType: type,
          languageCode: lCode,
          countryCode: cCode,
        });
      }
    };
    const createObserver = () => {
      const options1 = {
        root: null, // 使用视口作为容器
        rootMargin: "0px",
        threshold: 0.6, // 当加载组件 100% 进入视口时触发回调
      };

      observer1.value = new IntersectionObserver(handleIntersect1, options1);
      observer1.value.observe(loader1.value);
    };
    // const createObserver2 = () => {
    //   const options2 = {
    //     root: null, // 使用视口作为容器
    //     rootMargin: "0px",
    //     threshold: 0.6, // 当加载组件 100% 进入视口时触发回调
    //   };

    //   observer2.value = new IntersectionObserver(handleIntersect2, options2);
    //   observer2.value.observe(loader2.value);
    // };
    const handleIntersect1 = (entries) => {
      if (entries[0].isIntersecting) {
        //加载数据
        sendGetNewsList(0, selectedType.value);
      }
    };
    // const handleIntersect2 = (entries) => {
    //   if (entries[0].isIntersecting) {
    //     //加载数据
    //     sendGetNewsList(0, selectedType.value);
    //   }
    // };
    //规范化时间
    const formatTime = (item) => {
      if (item !== null) {
        const timestamp = item.time;
        const date = new Date(timestamp);

        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // 月份从0开始，需要加1
        const day = String(date.getUTCDate()).padStart(2, "0");
        const hours = String(date.getUTCHours()).padStart(2, "0");
        const minutes = String(date.getUTCMinutes()).padStart(2, "0");
        const seconds = String(date.getUTCSeconds()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
      } else {
        return;
      }
    };
    //跳转新闻页
    const toNewsDetail = (key) => {
      if (newsList.value[key].userId) {
        store.dispatch("Home/addLook", { newId: newsList.value[key].id });
      }
      store.commit("Home/setType", selectedType.value);
      router.push({ path: "/navFrame", query: { key: key } });
    };

    watch(
      () => [languageCode.value, countryCode.value, selectedType.value],
      () => {
        sendGetNewsList(1, selectedType.value);
      }
    );
    onMounted(() => {
      createObserver();
    });
    return {
      newsList,
      selectedType,
      newTypes,
      selectType,
      formatTime,
      toNewsDetail,
      loader1,
      onChange,
      // loader2,
    };
  },
};
</script>

<style lang="less">
.item-text {
  height: 100%;
}
.href:hover,
.href:focus {
  color: rgb(18, 18, 134);
  text-decoration: underline;
}
.href {
  color: black;
  outline: none;
  text-decoration: none;
}
.home1:hover {
  color: red;
}
.home1 {
  color: #888;
}
.scroll-container {
  overflow-x: auto;
  overflow-y: hidden;
  /* 水平滚动条 */
}
.main-right {
  width: 100%;
  background-color: rgb(249, 249, 249);
}
// .customize-menu {
//   padding: 1rem 0;
// }
.slider_menu[sliderTab] {
  position: relative;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  border: 3px solid transparent;
  overflow: hidden;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  ul {
    margin-top: 0;
    overflow-y: auto;
    // overflow-x: hidden;
    white-space: nowrap;
    .nav {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
    }
  }
  .menu {
    position: relative;
    white-space: nowrap;
    flex-wrap: initial;

    li {
      display: list-item;
      text-align: -webkit-match-parent;
      unicode-bidi: isolate;
      cursor: pointer;
      width: auto;
      height: auto;

      a {
        height: 28px;
        line-height: 28px;
        padding: 0 12px;
        display: block;
        text-decoration: none;
        position: relative;
        color: #888;
        // height: 40px;
        // line-height: 40px;
        text-align: center;
        font-size: 1rem;
        // padding: 0 16px;
        -webkit-transition: 0.25s;
        transition: 0.25s;
      }
      a:hover {
        color: #fff;
      }
      a:active {
        color: #fff;
      }
    }
    .active-tab {
      color: #fff;
      background: #f1404b;
      border-radius: 100px;
      // width: 60px;
      // height: 40px;
      transition: 0.73s;
      a {
        color: #fff;
      }
    }
  }
}
.newitem {
  background: #fff;
  border-radius: 10px;
  min-width: 400px;
  padding: 20px;
  margin: 10px;
  .image {
    width: 380x;
    height: 200px;
    border-radius: 10px;
  }
}
.newitem2 {
  background: #fff;
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  .image {
    max-width: 100%;

    border-radius: 10px;
  }
}
.scroll-container .newitem {
  display: inline-block;
  // width: calc(100% / 3);
}
</style>
